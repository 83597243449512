import React, { useState, useRef, useEffect } from 'react';
import { StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import LocalImage from '../localImage';
import './styles.scss';

const Footer = ({ data: { prismic } }) => {
	const data = prismic.allFooters.edges[0].node;

	const [currentImage, setCurrentImage] = useState(null);

	const imagesTransitionEl = useRef(null);

	let transitionTimeout = null;

	const LinkSwitch = ({ data, children }) => {
		if (data.link)
			return <a href={data.link.url} target="_blank"> {children} </a>;
		else
			return children;
	}

	const handleMouseEnter = (imageData) => {
		if (!imageData) return;
		clearTimeout(transitionTimeout);
		if (currentImage !== null) {
			imagesTransitionEl.current.classList.remove('visible');
			transitionTimeout = setTimeout(() => {
				setCurrentImage(imageData);
				imagesTransitionEl.current.classList.add('visible');
			}, 300)
		} else {
			clearTimeout(transitionTimeout);
			setCurrentImage(imageData);
			imagesTransitionEl.current.classList.add('visible');
		}
	}

	const handleMouseLeave = () => {
		clearTimeout(transitionTimeout);
		imagesTransitionEl.current.classList.remove('visible');
		transitionTimeout = setTimeout(() => {
			setCurrentImage(null);
		}, 300)
	}

	return (
		<div className="Footer" data-scroll>
			<div className="Footer__Container" data-scroll data-scroll-speed="-8" data-scroll-position="bottom">
				<div className="Footer__Left">
					<div className="Footer__Blocks">
						<div className="Footer__Block">
							<h4 className="Footer__FooterSubtitle"> {data.left_block_title[0].text} </h4>
							<ul className="Footer__BlockLines">
								{data.left_block_lines.map((line, index) => (
									<li key={`footerblock-${index}`}>
										<LinkSwitch data={line}>
											<span
												onMouseEnter={() => handleMouseEnter(line.imageSharp)}
												onMouseLeave={handleMouseLeave}
												data-cursor-scale
											>
												{line.text[0].text}
											</span>
										</LinkSwitch>
									</li>
								))}
							</ul>
						</div>
						<div className="Footer__Block">
							<h4 className="Footer__FooterSubtitle"> {data.right_block_title[0].text} </h4>
							<ul className="Footer__BlockLines">
								{data.right_block_lines.map((line, index) => (
									<li key={`footerblock-${index}`}>
										<LinkSwitch data={line}>
											<span
												onMouseEnter={() => handleMouseEnter(line.imageSharp)}
												onMouseLeave={handleMouseLeave}
												data-cursor-scale
											>
												{line.text[0].text}
											</span>
										</LinkSwitch>
									</li>
								))}
							</ul>
						</div>
					</div>
					<div className="Footer__LeftBottom">
						<h4 className="Footer__FooterSubtitle"> {data.socials_title[0].text} </h4>
						<ul className="Footer__Socials">
							{data.socials_list.map((social, index) => (
								<li
									onMouseEnter={() => handleMouseEnter(social.imageSharp)}
									onMouseLeave={handleMouseLeave}
									key={`social-${index}`}
									data-cursor-scale
								>
									<div className="circle" style={{backgroundColor: social.color}}></div>
									<a href={social.link.url} target="_blank">
										{social.name[0].text}
									</a>
								</li>
							))}
						</ul>
					</div>
				</div>
				<div className="Footer__Images">
					<div className="Footer__ImagesTransition" ref={imagesTransitionEl}>
						{currentImage &&
							<Img fluid={currentImage.childImageSharp.fluid} />
						}
					</div>
				</div>
			</div>
			<div className="Footer__Smiley">
				<LocalImage filename="Smiley-White.png" />
			</div>
		</div>
	)
}

export default () => (
	<StaticQuery
		query={graphql`
			query FooterQuery {
				prismic {
					allFooters {
						edges {
							node {
								left_block_title
								right_block_title
								left_block_lines {
									image
									imageSharp {
										childImageSharp {
											fluid(maxWidth: 2000) {
												...GatsbyImageSharpFluid
											}
										}
									}
									link {
										... on PRISMIC__ExternalLink {
											url
										}
									}
									text
								}
								right_block_lines {
									imageSharp {
										childImageSharp {
											fluid(maxWidth: 2000) {
												...GatsbyImageSharpFluid
											}
										}
									}
									image
									link {
										... on PRISMIC__ExternalLink {
											url
										}
									}
									text
								}
								socials_title
								socials_list {
									color
									link {
										... on PRISMIC__ExternalLink {
											_linkType
											url
										}
									}
									name
									imageSharp {
										childImageSharp {
											fluid(maxWidth: 2000) {
												...GatsbyImageSharpFluid
											}
										}
									}
									image
								}
							}
						}
					}
				}
			}
		`}
		render={data => (
			<Footer data={data} />
		)}
	/>
)
