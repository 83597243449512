import React, { useRef, useEffect, useState, useContext } from "react"
import PropTypes from "prop-types"
import LocomotiveScroll from "locomotive-scroll"

import { ContextLoader } from '../../context';
import './styles.scss';

const Scroll = ({ children, backToTopRequest, onScroll = null }) => {

	const [isLoading] = useContext(ContextLoader);

	// Scroll variables
	const scrollEL = useRef(null);
	const [scroll, setScroll] = useState(null);

	// Init scroll
	useEffect(() => {
		if (isLoading === true) return;

		const newScroll = new LocomotiveScroll({
			el: scrollEL.current,
			smooth: true,
			getSpeed: true
		});

		setScroll(newScroll);

		if (localStorage.getItem('is-touch') === 'false') {
			if (onScroll) {
				newScroll.on('scroll', handleScroll);
			}
		}

		return function clean () {
			newScroll.destroy();
    };
	}, [isLoading])

	useEffect(() => {
		if (scroll) scroll.scrollTo(0);
	}, [backToTopRequest])

	useEffect(() => {
		// Listen for stop and start scroll event
		window.addEventListener('stopScrollEvent', stopScroll);
		window.addEventListener('startScrollEvent', startScroll);
		return function clean () {
			window.removeEventListener('stopScrollEvent', stopScroll);
			window.removeEventListener('startScrollEvent', startScroll);
		}
	})

	const handleScroll = e => {
		if (onScroll) {
			onScroll(e);
			const newScrollEvent = new CustomEvent('customScrollEvent', {
				"detail": {'y': e.delta.y }
			});
			window.dispatchEvent(newScrollEvent);
		}
	}

	const stopScroll = () => {
		scroll.stop();
	}

	const startScroll = () => {
		scroll.start();
	}

	return (
		<>
			<div
				className="Scroll"
				ref={scrollEL}
				data-scroll-container
				id="fixed-elements-target"
			>
				{children}
			</div>
		</>
	)
}

Scroll.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Scroll;